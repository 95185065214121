<template>
  <div id="app">
    <!-- <el-row>
      <el-col :span="24" style="margin:10px 0">
        <el-page-header @back="goSelect" content="CRT点位管理"> </el-page-header>
      </el-col>
    </el-row> -->
    <div class="container">
      <dv-border-box-3 class="container-left" :color="['#2862b7', '#2862b7']">
        <div style="overflow:scroll; height:100%;">
          <el-input type="text" placeholder="输入关键字进行搜索" v-model="filterText"
            style="margin:10px 10px 0;width:93%;height:35px;outline:none;background:rgba(0,0,0,0.2);color:#fff;border:2px solid #2862b7;border-radius:5px"></el-input>
          <div>
            <el-select v-model="sortVal" placeholder="请选择排序方式"
              style="border:2px solid #019da5;border-radius:5px;margin: 5px 0 0 10px;height:35px;line-height:35px">
              <el-option v-for="item in sortList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <span class="sort" @click="searchSort">查询</span>
          </div>
          <!-- 侧边公司\CRT展示 -->
          <el-tree :filter-node-method="filterNode" ref="tree" :default-expand-all="false" :data="crtInfoOptions"
            :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>
      </dv-border-box-3>
      <dv-border-box-10 class="container-center" :color="['#2862b7', '#2862b7']">
        <el-row :gutter="0" v-drag>
          <el-col :span="24" :offset="0">
            <div>
              <!-- @hide="popoverHide" -->
              <el-popover title="CRT点位添加" placement="right-start" trigger="click" @show="openPopover" @hide="popoverHide">
                <el-row :gutter="12" style="width:100%;">
                  <el-col :span="10" :offset="0">
                    <el-input v-model="deviceInfoQuery.id" placeholder="请输入设备编号" size="normal" clearable></el-input>
                  </el-col>
                  <el-col :span="10" :offset="0">
                    <el-input v-model="deviceInfoQuery.typeName" placeholder="请输入设备类型" size="normal" clearable></el-input>
                  </el-col>
                  <el-col :span="4" :offset="0">
                    <el-button type="primary" @click="getNoCoordinateDeviceInfoList">搜索</el-button>
                  </el-col>
                </el-row>

                <el-table :data="deviceInfoList" max-height="400px" @current-change="handleCurrentChange"
                  highlight-current-row>
                  <el-table-column fixed="left" type="index" label="序号" width="60" align="center"></el-table-column>
                  <el-table-column property="id" label="设备编号" align="center"></el-table-column>
                  <el-table-column property="typeName" label="设备类型" align="center"></el-table-column>
                </el-table>

                <div style="margin-top:10px">
                  <span>图标尺寸(单位：px)</span>
                  <el-slider :min="5" :max="200" v-model="crtDeviceCoordinateInfo.iconWidth"></el-slider>
                </div>

                <div style="text-align:right">
                  <el-button type="primary" size="default" @click="realAddCoordinate"
                    v-if="userRightList.addJuris == '1'">添加</el-button>
                </div>

                <!-- 分页 -->
                <div style="height:30px;width:100%;">
                  <el-pagination :pager-count='count' background :current-page.sync="current" :page-size="limit"
                    :total="total" layout="total, prev, pager, next, jumper"
                    @current-change="getNoCoordinateDeviceInfoList"></el-pagination>
                </div>

                <div id="showListForCheck" slot="reference">
                  <i class="el-icon-location"></i>
                </div>
              </el-popover>
              <!--前端设备点位-->
              <img v-for="item in crtDeviceCoorcrtDinateList" :id="item.deviceInfoId" :src="baseUrl + item.iconUrl"
                :style="{ 'left': (parseFloat(item.coordinatex) * crtWid - parseFloat(item.iconWidth) / 2).toString() + 'px',
                         'top': (parseFloat(item.coordinatey) * crtHei - parseFloat(item.iconWidth) / 2).toString() + 'px', 
                           'width': item.iconWidth + 'px', 'position': 'absolute', 'z-index': '2', 'cursor': 'pointer' }"
                @click="showDeviceInfo(item)">
              <img v-if="crtId != ''" id="crt" :src="baseUrl + crtUrl" @click="addCoordinate">
            </div>
          </el-col>
        </el-row>
        <!-- 空白页 -->
        <div v-if="crtId == ''" class="tips">请选择CRT...</div>
      </dv-border-box-10>

      <dv-border-box-10 class="container-right" :color="['#2862b7', '#2862b7']">
        <!-- crt设备点位展示 -->
        <div style="height:10%">
          <el-row class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-input icon="search" v-model="addedDeviceInfoQuery.id" placeholder="输入 < 设备编号 > 进行查找" size="normal"
                @input="getAddedDeviceInfoList" clearable>
                <template slot="prepend"><i class="el-icon-search"></i></template>
              </el-input>
            </el-col>
          </el-row>
          <el-row class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-input v-model="addedDeviceInfoQuery.typeName" placeholder="输入 < 设备类型 > 进行查找" size="normal"
                @input="getAddedDeviceInfoList" clearable>
                <template slot="prepend"><i class="el-icon-search"></i></template>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div style="height:80%">
          <el-row :gutter="0" class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-table :data="addedDeviceInfoList" :max-height="800" highlight-current-row>
                <el-table-column prop="id" label="设备编号" width="160" align="center"></el-table-column>
                <el-table-column prop="typeName" label="设备类型" width="120" align="center"></el-table-column>
                <el-table-column label="操作" width="145" align="center">
                  <template slot-scope="scope">
                    <el-button @click="handlePosition(scope.row.id)" @blur="recoveryPosition(scope.row.id)" type="success"
                      size="small">定位</el-button>
                    <el-button @click="recoveryPosition(scope.row.id)" type="danger" size="small">取消</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </dv-border-box-10>
    </div>

    <!-- 点击添加的 crt 点位进行的操作 修改图标尺寸、删除点位 -->
    <el-dialog title="设备信息详情" :visible.sync="deviceInfoDialogVisible" width="40%" @close="clearDeviceInfo"
      style="background:rgba(0,0,0,0.4)">
      <el-form label-width="120px" :model="deviceInfo">
        <el-form-item label="设备ID">
          <el-input v-model="deviceInfo.id" disabled />
        </el-form-item>
        <el-form-item label="设备类型">
          <el-input v-model="deviceInfo.typeName" disabled />
        </el-form-item>
        <el-form-item label="属性">
          <el-input v-model="nameList" disabled />
        </el-form-item>
        <el-form-item label="值">
          <el-input v-model="valueList" disabled />
        </el-form-item>
        <el-form-item label="尺寸">
          <span>图标尺寸(单位:px)</span>
          <el-slider :min="5" :max="100" v-model="crtDeviceCoordinateInfo.iconWidth" @change="updateIconSize"></el-slider>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deviceInfoDialogVisible= false">取 消</el-button>
        <el-button type="danger" @click="removeCoordinate" v-if="userRightList.delJuris == '1'">删除点位</el-button>
      </div>
</el-dialog></div></template>

<script>
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import CRT from "@/api/manageApi/CrtInfo";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import BaseData from "@/assets/config/BaseData.js";

import drag from "@/../utils/drag.js";
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
export default {
  data() {
    return {
      // crt查询
      filterText: "",
      //   crt列表
      crtInfoOptions: [],
      //   crt列表显示
      //   defaultProps: {
      //     children: "crtUrlVoList", // 子公司节点
      //     label: "ciIdName", // 公司名
      //     value: "crtUrl",
      //   },
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "crtUrl",
      },
      valueList: '',
      nameList: '',
      total: 0,
      current: 1,
      limit: 10,
      count: 5,

      LSX: 0,
      LSY: 0,
      //crt信息列表
      crtInfoList: [],
      //当前选中的crt对象
      crtInfo: {},
      crtId: "",
      crtUrl: "",
      //未绑定点位的设备信息列表
      deviceInfoList: [],
      //已绑定点位的设备信息列表
      addedDeviceInfoList: [],
      //点位列表
      crtDeviceCoorcrtDinateList: [],
      //添加点位的宽度和高度比例  imgWid为 距离左边界的距离/图片的宽度  imgHei为 距离上边界的距离/图片的高度
      crtDeviceCoordinateInfo: {
        id: "",
        deviceInfoId: "",
        crtPictureId: "",
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 15,
      },
      sortList: [{
        name: '时间正序',
        value: 'timeAsc',
      }, {
        name: '时间倒序',
        value: 'timeDesc',
      }, {
        name: '序号正序',
        value: 'sortIdAsc',
      }, {
        name: '序号倒序',
        value: 'sortIdDesc',
      }],
      sortVal: '',
      //添加点位弹框内模糊查询
      deviceInfoQuery: {
        id: "",
        typeName: "",
        // place: "",
      },
      addedDeviceInfoQuery: {
        id: "",
        typeName: "",
        crtPictureId: "",
      },
      //设备列表当前选中
      currentRow: null,
      //设备信息详情
      deviceInfo: {},

      //当前crt图的宽度
      crtWid: 0,
      //当前crt图的高度
      crtHei: 0,
      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,
      //添加点位弹框展示状态
      popoverShow: false,
      //设备信息详情弹框标志位
      deviceInfoDialogVisible: false,

      userRightList: [],
    };
  },

  created() {
    this.getUsers()

    // 获取所有公司及公司下的crt
    this.getAllCrtInfo();

    this.init();
  },

  watch: {
    //监听弹框是否展示   若果弹框展示图标则展示  弹框不展示图标隐藏
    popoverShow(newVal, oldVal) {
      if (newVal) {
        document.getElementById("showListForCheck").style.opacity = 1;
      } else {
        document.getElementById("showListForCheck").style.opacity = 0;
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },

  methods: {
    searchSort() {
      if (this.sortVal == '') {
        this.$message.warning('请选择排序方式！')
      } else {
        console.log(this.sortVal);
        CRT.getCrtListByCiIdStructure(this.sortVal).then(res => {
          this.crtInfoOptions = res.data.data.list
          console.log(this.crtInfoOptions);
        })
      }
    },
    init() {
      this.getCrtInfoList();
      //   this.getNoCoordinateDeviceInfoList();
    },
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "CRTManagement") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    goSelect() {
      this.$router.push("/Select");
    },

    // 获取所有公司及公司下的crt
    async getAllCrtInfo() {
      //   const { data: res } = await CRT.getCrtByCiId()
      //   this.crtInfoOptions = res.data.list;


      const { data: res } = await CRT.getCrtListByCiIdStructure()
      console.log(res);
      this.crtInfoOptions = res.data.list;
      console.log(this.crtInfoOptions);
    },

    // 所有未绑定的点位信息
    async getNoCoordinateDeviceInfoList() {
      const { data: res } = await deviceInfoManagement.getNoCoordinateDeviceInfoList(this.current, this.limit, this.deviceInfoQuery)
      this.total = res.data.total;
      this.deviceInfoList = res.data.list;
    },

    getAddedDeviceInfoList() {
      deviceInfoManagement.getAddedDeviceInfoList(this.addedDeviceInfoQuery)
        .then((res) => {
          this.addedDeviceInfoList = res.data.data.list;
        });
    },

    handlePosition(id) {
      var hr = document.getElementsByClassName("heart_red");
      if (hr.length != 0) {
        hr[0].classList.remove("heart_red");
      }
      document.getElementById(id).classList.add("heart_red");
    },

    // 按钮市区焦点时取消定位设备
    recoveryPosition(id) {
      document.getElementById(id).classList.remove("heart_red");
    },

    //查詢全部CRT图片信息
    async getCrtInfoList() {
      const { data: res } = await CRT.getAllCrt()
      this.crtInfoList = res.data.list;
    },

    //设备信息列表当前选中改变时触发
    handleCurrentChange(val) {
      this.currentRow = val;
      if (val) {
        this.crtDeviceCoordinateInfo.deviceInfoId = this.currentRow.id;
      }
    },

    //展示点位信息
    async showDeviceInfo(item) {
      console.log(item);
      this.crtDeviceCoordinateInfo.id = item.id;
      this.crtDeviceCoordinateInfo.iconWidth = Number(item.iconWidth);
      // // TODO 展示点位信息
      const { data: res } = await deviceInfoManagement.getDeviceInfoById(item.deviceInfoId)
      console.log(res);
      this.deviceInfo = res.data.deviceInfoVo;
      this.nameList = this.deviceInfo.name.join(',')
      this.valueList = this.deviceInfo.value.join(',')
      this.deviceInfoDialogVisible = true;
    },

    //关闭设备详情的时候清空设备信息详情
    clearDeviceInfo() {
      this.clearCrtDeviceCoordinateInfo();
      this.deviceInfo = {};
    },

    // 修改图片大小
    async updateIconSize() {
      console.log(this.crtDeviceCoordinateInfo);
      const { data: res } = await crtDeviceCoordinate.updateIconSize(this.crtDeviceCoordinateInfo)

      //提示信息
      this.$message({
        type: res.success ? "success" : "warning",
        message: res.message,
      });
      //   //刷新
      if (res.success) {
        this.getCoordinateList();
        this.getNoCoordinateDeviceInfoList();
      }
    },

    /************************图片操作************************/
    openPopover() {
      this.getNoCoordinateDeviceInfoList()
      this.popoverShow = true
    },
    //弹框隐藏是触发
    popoverHide() {
      this.popoverShow = false;
      this.currentRow = null;
      this.clearCrtDeviceCoordinateInfo();
    },

    //crt图片改变时的回调函数
    handleNodeClick(data) {
      // 切换 crt 调用方法关闭弹框
      this.crtDeviceCoorcrtDinateList = [];
      this.addedDeviceInfoList = [];
      // 拆分crt图片 和 crt路径
      let CRTInfo = data.id.split('/')
      // crtid
      let crtId = CRTInfo[0].split('-')[1]
      // crt路径
      let crtUrl = 'crtPicture/' + CRTInfo[1]


      if (crtId == undefined) {
        this.crtId = "";
      } else {

        this.crtId = crtId

        this.crtInfoList.forEach(element => {
          if (crtId == element.id) {
            this.crtUrl = element.url
          }
        });

        this.getNoCoordinateDeviceInfoList();

        this.crtInfoList.forEach((item) => {
          if (item.id == this.crtId) {
            this.crtUrl = item.url;
            console.log(this.crtUrl);
          }
        });

        this.crtDeviceCoordinateInfo.crtPictureId = this.crtId;
        this.addedDeviceInfoQuery.crtPictureId = this.crtId;
        setTimeout(() => {
          var crt = document.getElementById("crt");
          this.crtWid = crt.width;
          this.crtHei = crt.height;
          this.getCoordinateList();
          this.getAddedDeviceInfoList();
        }, 500);
      }

    },

    //删除点位
    removeCoordinate() {
      this.$confirm(
        "此操作将永久删除该CRT以及该CRT绑定的点位, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        //点击确定，指定then方法
        crtDeviceCoordinate
          .removeCoordinateById(this.crtDeviceCoordinateInfo.id)
          .then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            //刷新
            if (res.data.success) {
              this.getCoordinateList();
              this.getNoCoordinateDeviceInfoList();
              this.getAddedDeviceInfoList();
              this.deviceInfoDialogVisible = false;
            }
          });
      });
    },

    //根据当前选中的crt图片id获取点位
    async getCoordinateList() {
      const { data: res } = await crtDeviceCoordinate.getCoordinateByCrtId(this.crtId)
      this.crtDeviceCoorcrtDinateList = res.data.list;
    },

    //添加点位
    addCoordinate(e) {
      console.log(e);
      e = e || window.event;
      let xx = e.offsetX;
      let yy = e.offsetY;

      let im = document.getElementById("crt"); // CRT背景图片
      // 计算点位和CRT图的比值
      this.crtDeviceCoordinateInfo.coordinatex = xx / im.width;
      this.crtDeviceCoordinateInfo.coordinatey = yy / im.height;
      //   console.log(this.crtDeviceCoordinateInfo.coordinatex);
      //   console.log(this.crtDeviceCoordinateInfo.coordinatey);

      this.LSX = xx / im.width;
      this.LSY = yy / im.height;


      setTimeout(() => {
        let a = document.getElementById("showListForCheck");
        a.click();
      }, 100);

      document.getElementById("showListForCheck").style.left = xx - 7 + "px";
      document.getElementById("showListForCheck").style.top = yy - 13 + "px";
    },

    //确认添加点位
    realAddCoordinate() {
      //校验是否选择设备
      if (!this.crtDeviceCoordinateInfo.deviceInfoId) {
        //提示信息
        this.$message({
          type: "warning",
          message: "请选择设备！",
        });
      } else {
        this.crtDeviceCoordinateInfo.coordinatex = this.LSX;
        this.crtDeviceCoordinateInfo.coordinatey = this.LSY;

        //添加绑定关系
        crtDeviceCoordinate
          .addCrtDeviceCoordinate(this.crtDeviceCoordinateInfo)
          .then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });

            this.deviceInfoQuery.id = "";
            //刷新
            // if (res.data.success) {
            //根据当前选中的crt图片id获取点位
            this.getCoordinateList();
            // 條件查询全部已绑定点位的设备信息
            this.getAddedDeviceInfoList();
            //條件查询全部未绑定点位的设备信息
            this.getNoCoordinateDeviceInfoList();
            //取消
            //   this.closePopover();
            this.popoverShow = false
            document.getElementById('app').click()
            // }
          });
      }
    },

    clearCrtDeviceCoordinateInfo() {
      this.crtDeviceCoordinateInfo = {
        id: "",
        deviceInfoId: "",
        crtPictureId: this.crtId,
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 15,
      };
    },

    /************************************************/
    // 筛选crt
    filterNode(value, data) {
      console.log(data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    //返回上一级
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/scrollBar";

// #overburden {
//   width: 700px;
//   border: 1px solid pink;
// }
/deep/.el-popover {
  width: 700px !important;
}

#crt {
  width: 1200px;
  position: relative;
}

#tempText {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  margin-top: 250px;
}

#showListForCheck {
  opacity: 0;
  position: absolute;
  color: red;
  z-index: 2;
}

.select-box {
  width: 300px;
  height: 500px;
  overflow: hidden;
}

// /deep/.el-pagination {
//   text-align: left;
// }

/deep/.el-tree {
  padding: 10px 0;
  scrollbar-width: none;
  user-select: none;
  background: none;
  height: 86vh;
  overflow: scroll;
  box-sizing: border-box;

  //   border: 1px solid green;
  .el-tree-node__label {
    font-size: 18px;
    font-weight: 500;
    color: rgb(62, 177, 243);
  }

  .el-tree-node__content {
    height: 35px;
  }
}

::-webkit-scrollbar {
  // display: none;
}

// 结构样式
.container {
  width: 100%;
  height: 90vh;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;

  .container-left {
    box-sizing: border-box;
    padding: 20px;
    height: 90vh;
    width: 20%;
  }

  .container-center {
    width: 58%;
    margin: 0 10px;
    box-sizing: border-box;

    .crt-continer {
      position: absolute;
      box-sizing: border-box;

      div {
        box-sizing: border-box;
        width: 100%;
        // height: 100vh;
        // border: 1px solid rgb(32, 19, 224);

        .crt-bg {
          width: 1200px;
          cursor: pointer;
        }
      }
    }

    // border: 1px solid red;
  }

  .container-right {
    height: 90vh;
    width: 24%;
    box-sizing: border-box;
    // border: 1px solid red;

    .container-right-item {
      margin: 10px;
    }
  }
}

.tips {
  width: 100%;
  font-size: 38px;
  text-align: center;
  margin-top: 30%;
  letter-spacing: 5px;
  font-weight: 600;
  color: cadetblue;
  user-select: none;
  font-family: "楷体";
}

// el-table 表格 透明样式
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  margin-top: 5px;
  background-color: transparent;
}

/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 18px;
  font-weight: 600;
  color: rgb(62, 177, 243);
}

/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 16px;
  color: rgb(62, 177, 243);
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

/deep/ th.el-table__cell.is-leaf {
  border: none;
}

/deep/.el-input__inner {
  background: rgba(0, 0, 0, 0.1);
  color: #3eb1f3;
  font-size: 16px;
  border: none;
}

/deep/.el-input-group__prepend {
  background: rgba(111, 147, 245, 0.8);
  color: rgb(243, 247, 250);
  font-weight: 600;
  font-size: 20px;
  border: none;
}

/deep/.el-table::before {
  height: 0px;
}

/deep/.el-table__empty-text {
  line-height: 560px;
  font-size: 18px;
  letter-spacing: 3px;
}

/deep/.el-tree-node__content:hover {
  background: rgb(48, 79, 145);
}

/deep/.el-table--scrollable-y .el-table__body-wrapper {
  scrollbar-width: none;
}

.sort {
  // display: inline-block;
  width: 60px;
  cursor: pointer;
  padding: 10px;
  margin-left: 15px;
  border-radius: 5px;
  background: #1a6776;
}

.sort:hover {
  font-size: 16px;
}
</style>